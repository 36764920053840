<template>
  <div class="hidden h-full w-full flex-col items-stretch gap-2 @md:flex">
    <ImageGridRow v-for="{ id: rowId, images } in rows" :key="`row-${rowId}`">
      <ImageGridSlide
        v-for="image in images"
        :key="image.id"
        :class="getImageStyles({ width: image.width })"
        :image="image.image"
        :title="image.title"
        :url="image.url"
      />
    </ImageGridRow>
  </div>
</template>

<script setup lang="ts">
import { getImageStyles } from '@autobid/ui/utils/cva/getImageStyles'
import type { ImageGrid } from '@autobid/ui/types/components/ImageGrid'
import ImageGridRow from './ImageGridRow.vue'
import ImageGridSlide from './ImageGridSlide.vue'

interface Props {
  rows: ImageGrid[]
}

defineProps<Props>()
</script>
